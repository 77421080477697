import { StaticImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  GearsCogwhellsDarkImg,
  ContactPhotoImg,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  MessageIcon,
  MessageYellowIcon,
  PhoneIcon,
  PhoneYellowIcon,
  PlaceIcon,
  TimeYellowIcon,
  PhotoBannerDarkImg,
  LogoWimikoImg,
  LogoWimikoWhiteImg,
  WimikoPreviewImg,
} from '../img-components';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { EMAIL_VALIDATION_RULES } from '../../shared/consts';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';

const MenuItemClass = '-mx-4 py-[23px] pl-[23px] transition-all duration-300';
const MenuItemActiveClass =
  'bg-white font-bold text-primary shadow-[0px_3px_6px_#00000029]';
const SectionIds = ['about-us', 'offer', 'contact', 'directions'];

const IndexPage = () => {
  const fakeInputRef = useRef<HTMLInputElement>(null);
  const { handleSubmit, register, reset, formState } = useForm();
  const [token, setToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0);

  const handleVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [setToken]
  );
  const onSubmit = async (data: any) => {
    /* BOTs will try to fill any input, but we want this to be empty :) */
    if (fakeInputRef.current?.value) {
      return;
    }
    const formData = new FormData();
    formData.append('phone', data.phone);
    formData.append('token', token);
    try {
      setToken('');
      const response = await fetch(process.env.GATSBY_EMAIL_API_URL as any, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      reset();
      alert(
        'Dziękuję za kontakt. Postaram się zadzwonić tak szybko jak to możliwe'
      );
    } catch (e) {
      console.error(e);
      alert(
        'Natąpił problem przy wysyłaniu wiadomości. Spróbuj napisać do mnie wiadomość na adres wimiko@onet.eu'
      );
    } finally {
      setRefreshReCaptcha((v) => +!v);
    }
  };

  const [activeSection, setActiveSection] = useState<string>('');
  useEffect(() => {
    const scrollHandler = () => {
      const sectionTops = SectionIds.map((id) => ({
        id,
        elem: document.getElementById(id),
      }))
        .filter(({ elem }) => !!elem)
        .map(({ id, elem }) => ({ id, top: elem!.getBoundingClientRect().top }))
        .filter(({ top }) => top >= 0);
      const activeSectionId = sectionTops.length
        ? sectionTops[0].id
        : SectionIds[SectionIds.length - 1];
      setActiveSection(activeSectionId);
    };
    window.addEventListener('scroll', scrollHandler);
    setTimeout(scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);
  const getMenuItemClass = (id: string) =>
    classNames(MenuItemClass, id === activeSection ? MenuItemActiveClass : '');

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'lg:bg-[color:rgba(var(--page-bg-color))] scroll-smooth',
        }}
        htmlAttributes={{ class: 'scroll-smooth' }}
      >
        <meta key="og:title" name="og:title" content="WIMIKO" />
        <meta
          key="description"
          name="description"
          content="Skup złomu i metali kolorowych. Hurtowy i detaliczny"
        />
        <meta
          key="og:description"
          name="og:description"
          content="Skup złomu i metali kolorowych. Hurtowy i detaliczny"
        />
        <meta key="og:type" name="og:type" content="website" />
        <meta key="og:url" name="og:url" content="https://wimiko.pl" />
        <meta key="og:site_name" name="og:site_name" content="WIMIKO" />
        <meta key="og:image" name="og:image" content={WimikoPreviewImg} />
        <meta key="og:image:width" name="og:image:width" content="1024" />
        <meta key="og:image:height" name="og:image:height" content="536" />
      </Helmet>
      <main className="text-main">
        <section
          className="flex h-[60px] items-center justify-center bg-white lg:h-[122px]"
          id="logo"
        >
          <img
            className="h-[28px] lg:h-16 lg:w-[190px]"
            src={LogoWimikoImg}
            alt="Logo"
          />
        </section>
        <section
          className="relative mx-auto max-w-screen-4xl text-center text-white lg:py-[84px]"
          id="banner"
        >
          <div className="max-w-screen-3x mx-auto">
            <div className="relative px-6 pt-28 pb-32 lg:static lg:px-0 lg:pt-0 lg:pb-0">
              <div
                className="absolute top-0 left-0 right-0 bottom-0 -z-50 block h-full bg-cover bg-center bg-no-repeat"
                aria-hidden="true"
                style={{ backgroundImage: `url(${PhotoBannerDarkImg})` }}
              />
              <h1 className="h1 mx-auto mt-[66px] max-w-6xl text-white">
                Skup złomu i metali kolorowych{' '}
                <span className="mt-16 block text-[0.8em] text-primary lg:inline lg:text-[1em] lg:text-white">
                  Hurtowy i detaliczny
                </span>
              </h1>
              <h5 className="mt-20 px-4 text-lg lg:mt-10 lg:px-0 lg:text-3xl lg:font-medium">
                Doświadczenie i wysokie kwalifikacje gwarantujące sprawną
                obsługę
              </h5>
            </div>
            <div className="mp mt-7 flex flex-col justify-center gap-4 lg:mt-24 lg:flex-row">
              <div className="banner-tile">
                <p>Zadzwoń po więcej informacji</p>
                <div>
                  <PhoneYellowIcon />
                  <ul role="list">
                    <li>
                      <a href="tel:+48604778733">604 778 733</a>
                    </li>
                    <li>
                      <a href="tel:+48602106372">602 106 372</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="banner-tile">
                <p>Godziny otwarcia</p>
                <div>
                  <TimeYellowIcon />
                  <ul role="list">
                    <li>Pn - Pt godz. 8:00-16:00</li>
                    <li>Sobota godz. 8:00-13:00</li>
                  </ul>
                </div>
              </div>
              <div className="banner-tile">
                <p>Napisz na adres e-mail</p>
                <div>
                  <MessageYellowIcon />
                  <a href="mailto:wimiko@onet.eu">wimiko@onet.eu</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="col-span-12 col-start-1 mx-auto max-w-screen-3xl grid-cols-[122px_auto] gap-4 lg:grid">
          <aside className="hidden lg:block">
            <nav className="sticky top-[107px] mt-[107px]">
              <ul className="uppercase" role="list">
                <li className={getMenuItemClass('about-us')}>
                  <a className="col-start-5" href="#about-us">
                    O firmie
                  </a>
                </li>
                <li className={getMenuItemClass('offer')}>
                  <a href="#offer">Oferta</a>
                </li>
                <li className={getMenuItemClass('contact')}>
                  <a href="#contact">Kontakt</a>
                </li>
                <li className={getMenuItemClass('directions')}>
                  <a href="#directions">Dojazd</a>
                </li>
              </ul>
            </nav>
          </aside>
          <div className="lg:grid lg:grid-cols-11 lg:border-l lg:border-l-[color:D8D8D8]">
            <section
              className="flex flex-col-reverse py-10 lg:col-span-9 lg:col-start-2 lg:grid lg:grid-cols-9 lg:gap-4 lg:py-24"
              id="about-us"
            >
              <div className="mp col-span-4 row-start-2 mt-8 lg:mt-0">
                <p>
                  Nasza firma działa na rynku surowców od 2000 roku i jest firmą
                  rodzinną. W 2019 roku została przekształcona w Spółkę cywilną
                  i otrzymała nazwę Wimiko. Siedziba znajduje się w Modlnicy tuż
                  przy wyjeździe z Krakowa w stronę Olkusza (dokładny adres w
                  zakładce dojazd).
                </p>
                <p className="mt-2">
                  Nasza firma stawia na najwyższą jakość usług, dlatego na
                  naszym placu znajduje się waga najazdowa do 60T oraz
                  odpowiedni sprzęt do wyładunku towaru, tak by maksymalnie
                  skrócić czas rozładunku i zapewnić odpowiednią jakość obsługi
                  naszym klientom.
                </p>
                <p className="mt-2">
                  Ponadto posiadamy własny transport umożliwiający odbiór towaru
                  od klienta z firmy lub prywatnej posesji. Istnieje możliwość
                  podstawienia kontenera na złom u klienta.
                </p>
                <p className="mt-2">
                  Lata pracy i zdobytego doświadczenia zaowocowały pozyskaniem
                  rzeszy stałych, zadowolonych klientów, którzy współpracują z
                  Nami od wielu lat.
                </p>
              </div>
              <div className="col-span-4 col-start-6 row-span-2">
                <div className="relative">
                  <StaticImage
                    className="w-full lg:w-[90%]"
                    src="../images/wimiko_photo.jpg"
                    alt="Zdjęcie firmy Wimiko"
                    loading="eager"
                  />
                  <div
                    className="absolute bottom-0 left-0 right-0 -z-10 hidden h-[80%] w-full bg-primary lg:block"
                    aria-hidden="true"
                  />
                  <div
                    className="absolute bottom-[-30px] right-0 z-10 hidden h-[88px] w-1/2 items-center justify-center bg-primary text-xl font-bold leading-none -tracking-tighter text-white lg:flex"
                    aria-hidden="true"
                  >
                    <span className="pr-[40px]">
                      <i className="fw text-[1.5em]" />
                      WIMIKO
                    </span>
                  </div>
                </div>
              </div>
              <div className="mp col-span-4 row-start-1">
                <p className="text-lg text-primary">Najwyższa jakość usług</p>
                <h2 className="h2 mt-4 lg:mt-5">O naszej firmie</h2>
              </div>
            </section>
            <section
              className="mp relative col-span-11 col-start-1 gap-x-4 gap-y-16 pt-[52px] pb-8 text-white lg:grid lg:grid-cols-11 lg:pb-[70px] lg:pt-[84px]"
              id="offer"
            >
              <div
                className="absolute top-0 left-0 right-0 bottom-0 -z-50 block h-full bg-cover bg-center bg-no-repeat"
                aria-hidden="true"
                style={{ backgroundImage: `url('${GearsCogwhellsDarkImg}')` }}
              />
              <h2 className="h2 col-span-3 col-start-2 mb-[70px] text-white lg:mb-0">
                Stale poszerzamy naszą ofertę
              </h2>
              <div className="offer-tile">
                <Icon1 />
                <p>
                  Nasza główna działalność to detaliczny i hurtowy skup złomu i
                  metali kolorowych.
                </p>
              </div>
              <div className="offer-tile">
                <Icon2 />
                <p>
                  Posiadamy trzy rodzaje wagi: samochodowa (do 60 ton), waga
                  platformowa do 1500 kg oraz waga na drobne ilości złomu.
                </p>
              </div>
              <div className="offer-tile col-start-2">
                <Icon3 />
                <p>
                  W niedalekiej przyszłości firma poszerzy swoją ofertę o skup
                  makulatury.
                </p>
              </div>
              <div className="offer-tile">
                <Icon4 />
                <p>
                  Oferujemy konkurencyjne ceny oraz możliwość ich negocjacji
                  przy większych ilościach towaru.
                </p>
              </div>
              <div className="offer-tile">
                <Icon5 />
                <p>Posiadamy zezwolenie na zbieranie i transport odpadów.</p>
              </div>
            </section>
            <section
              className="mp relative col-span-11 grid-cols-11 gap-4 pt-[60px] pb-[40px] lg:grid lg:py-24"
              id="what-we-buy"
            >
              <div
                className="absolute top-0 left-0 right-0 -z-50 block h-full max-w-screen-3xl bg-[color:rgba(0,0,0,0.05)]"
                aria-hidden="true"
              />
              <div className="col-span-3 col-start-2">
                <h2 className="h2 mt-5 mb-7">Skupujemy</h2>
                <p className="max-w-xs">
                  Podstawą naszej działalności jest skup złomu stalowego i
                  metali kolorowych.
                </p>
              </div>
              <div className="col-span-3 mt-8 lg:mt-0">
                <div className="relative">
                  <StaticImage
                    src="../images/steel.jpg"
                    alt="Stal"
                    loading="eager"
                  />
                  <h5 className="h5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-3xl uppercase text-white">
                    Stal
                  </h5>
                </div>
                <ul className="ul" role="list">
                  <li>złom stalowy</li>
                  <li>złom żeliwa</li>
                  <li>stal nierdzewna</li>
                </ul>
              </div>
              <div className="col-span-3 mt-12 lg:mt-0">
                <div className="relative">
                  <StaticImage
                    src="../images/metals.jpg"
                    alt="Metale kolorowe"
                    loading="eager"
                  />
                  <h5 className="h5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-3xl uppercase text-white">
                    Metale kolorowe
                  </h5>
                </div>
                <ul className="ul" role="list">
                  <li>aluminium – wszystkie rodzaje</li>
                  <li>puszki aluminiowe</li>
                  <li>miedź – wszystkie rodzaje</li>
                  <li>mosiądz – żółty, pobiał</li>
                  <li>cynk</li>
                  <li>ołów</li>
                  <li>znal</li>
                  <li>chłodnice – wszystkie rodzaje</li>
                </ul>
              </div>
            </section>
            <section
              className="col-span-11 col-start-1 grid-cols-11 text-base lg:grid lg:text-lg lg:shadow-[0px_3px_6px_#00000029]"
              id="contact"
            >
              <div className="mp relative col-span-4 grid-cols-4 gap-4 pb-[56px] lg:grid lg:pb-[164px] lg:pr-[82px]">
                <div
                  className="absolute top-0 left-0 right-0 bottom-0 -z-50 block h-full max-w-screen-3xl bg-contain"
                  aria-hidden="true"
                  style={{ backgroundImage: `url('${ContactPhotoImg}')` }}
                />
                <div className="col-span-3 col-start-2 pt-[50px] lg:pt-14">
                  <h2 className="h2 mt-5 mb-7">Kontakt</h2>
                  <p className="max-w-[276px]">
                    Jeśli chcesz uzyskać dodatkowe informacje - zostaw nam Twój
                    numer telefonu.
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      className="mt-4 w-full p-4 placeholder:text-main lg:mt-[83px]"
                      type="text"
                      placeholder="Nr telefonu"
                      {...register('phone', {
                        required: EMAIL_VALIDATION_RULES.phone.required,
                        maxLength: EMAIL_VALIDATION_RULES.phone.maxLength,
                      })}
                    />
                    <label className="mt-6 flex items-center text-xs">
                      <input
                        className="mr-3 flex-shrink-0"
                        type="checkbox"
                        {...register('agreement', {
                          required: true,
                        })}
                      />
                      <span>
                        Zapoznałem się z{' '}
                        <Link className="underline" to="privacy-policy">
                          polityką prywatności
                        </Link>
                      </span>
                    </label>
                    <button
                      className="btn-black mt-8"
                      type="submit"
                      disabled={!token || !formState.isValid}
                    >
                      Wyślij
                      <i className="fw" />
                    </button>
                    <input
                      className="hidden h-0 w-0"
                      type="text"
                      ref={fakeInputRef}
                    />
                  </form>
                  <GoogleReCaptcha
                    onVerify={handleVerify}
                    refreshReCaptcha={refreshReCaptcha}
                    action="email"
                  />
                </div>
              </div>
              <div className="mp col-span-7 mx-[22px] flex translate-y-[-30px] flex-col gap-16 bg-white pt-6 pb-12 shadow-[0px_3px_6px_#00000029] lg:mx-0 lg:translate-y-0 lg:pb-0 lg:pt-28 lg:pl-[100px] lg:shadow-none xl:flex-row xl:justify-between xl:gap-0">
                <div>
                  <h5 className="h5">Dane kontaktowe</h5>
                  <ul className="mt-8 flex flex-col gap-6" role="list">
                    <li className="flex gap-8">
                      <PlaceIcon className="w-4 lg:w-8" />
                      <span>
                        Częstochowska 40, <br />
                        32-085 Modlnica
                      </span>
                    </li>
                    <li className="flex gap-8">
                      <MessageIcon className="w-4 lg:w-8" />
                      <a href="mailto:wimiko@onet.eu">wimiko@onet.eu</a>
                    </li>
                    <li className="flex gap-8">
                      <PhoneIcon className="w-4 lg:w-8" />
                      <span>
                        <a href="tel:+48604778733">604 778 733</a> <br />
                        <a href="tel:+48602106372"> 602 106 372</a>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="lg:mr-[122px]">
                  <h5 className="h5">Godziny otwarcia</h5>
                  <ul className="mt-8 flex flex-col gap-8" role="list">
                    <li className="flex justify-between">
                      <span className="mr-11">Poniedziałek-Piątek</span>
                      <span>8:00-16:00</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="mr-11">Sobota</span>
                      <span>8:00-13:00</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="col-span-11 pt-10 lg:pt-44" id="directions">
              <h2 className="h2 mp lg:text-center">Dojazd</h2>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.140400263544!2d19.868238016711693!3d50.12109467943125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471659532afd0b1b%3A0x91cfea4f3fb9128!2sWimiKo%20S.C.%20Skup%20z%C5%82omu%20stalowego%20i%20metali%20kolorowych!5e0!3m2!1sen!2spl!4v1667941891525!5m2!1sen!2spl"
                width="100%"
                height="628px"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </section>
          </div>
        </div>
      </main>
      <footer className="mx-auto bg-[color:rgb(var(--footer-bg-color))] pt-12 pb-12 lg:pb-32 lg:pt-24">
        <div className="mp mx-auto max-w-screen-3xl grid-cols-12 items-center text-white lg:grid">
          <img
            className="col-span-2 col-start-2 h-full w-[108px] lg:w-full"
            src={LogoWimikoWhiteImg}
            alt="Logo"
          />
          <nav className="col-span-6 col-start-5 mt-8 lg:mt-0">
            <ul
              className="flex grid-cols-6 flex-col gap-4 text-sm uppercase lg:grid lg:gap-0 lg:text-lg"
              role="list"
            >
              <li>
                <a href="#about-us">O nas</a>
              </li>
              <li>
                <a href="#offer">Oferta</a>
              </li>
              <li>
                <a href="#contact">Kontakt</a>
              </li>
              <li>
                <a href="#directions">Dojazd</a>
              </li>
              <li className="col-span-2">
                <Link to="privacy-policy">Polityka Prywatności</Link>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
